<template>
  <div class="d-flex assistant-message">
    <div class="mr-2">
      <VAvatar size="28">
        <v-img :src="'images/app/assistants/icon-512x512.png'"  />
      </VAvatar>
    </div>
    <div v-html="getContent()"></div>
  </div>
</template>

<script setup>

import markdownit from 'markdown-it'

const prop = defineProps(['message'])

const getContent = () => {

  const md = markdownit()

  return md.render(prop.message.content[0].text.value)
}


</script>

<style lang="scss" scoped>
// .assistant-message :deep(p) {
//   padding-bottom: 8px;
// }

.assistant-message :deep(pre) {
  padding-bottom: 8px;
  list-style: none;
}

.assistant-message :deep(hr) {
  margin-top: 8px;
  margin-bottom: 8px;
}

.assistant-message :deep(li) {
  margin-top: 8px;
  padding-bottom: 8px;
  list-style: none;
}

.assistant-message :deep(a) {
  text-decoration: none;
  /* Remove underline */
  font-weight: bold;
  /* Make links bold */
  color: rgba(0, 0, 0, 0.87);
  /* Change link color */
}
</style>